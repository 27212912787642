import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPhoneFormatDirective } from '../directives/input-phone-format.directive';
import { NumberOnlyDirective } from '../directives/number-only.directive';

@NgModule({
  declarations: [InputPhoneFormatDirective, NumberOnlyDirective],
  imports: [
    CommonModule
  ],
  exports: [InputPhoneFormatDirective, NumberOnlyDirective]
})
export class SharedModule { }
