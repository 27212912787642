import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material';
import { DialerComponent } from './dialer.component';
import { CountUpModule } from 'ngx-countup'
import { BenefitDonutChartModule } from './../../../public/benefits/modules/benefit-donut-chart/benefit-donut-chart.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [DialerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    CountUpModule,
    BenefitDonutChartModule,
    TranslateModule
  ],
  exports: [DialerComponent],
})
export class DialerModule { }
